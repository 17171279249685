import React from "react";
import useBreakpoints from "../../hooks/useBreakpoints.ts";
const Youtube = () => {
  const breakpoint = useBreakpoints();
  const isMobile = ["xs"].includes(breakpoint);
  return isMobile ? (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.3333 19.9998L20.2533 15.9998L13.3333 11.9998V19.9998ZM28.7466 9.55984C28.92 10.1865 29.04 11.0265 29.12 12.0932C29.2133 13.1598 29.2533 14.0798 29.2533 14.8798L29.3333 15.9998C29.3333 18.9198 29.12 21.0665 28.7466 22.4398C28.4133 23.6398 27.64 24.4132 26.44 24.7465C25.8133 24.9198 24.6666 25.0398 22.9066 25.1198C21.1733 25.2132 19.5866 25.2532 18.12 25.2532L16 25.3332C10.4133 25.3332 6.93329 25.1198 5.55996 24.7465C4.35996 24.4132 3.58663 23.6398 3.25329 22.4398C3.07996 21.8132 2.95996 20.9732 2.87996 19.9065C2.78663 18.8398 2.74663 17.9198 2.74663 17.1198L2.66663 15.9998C2.66663 13.0798 2.87996 10.9332 3.25329 9.55984C3.58663 8.35984 4.35996 7.5865 5.55996 7.25317C6.18663 7.07984 7.33329 6.95984 9.09329 6.87984C10.8266 6.7865 12.4133 6.7465 13.88 6.7465L16 6.6665C21.5866 6.6665 25.0666 6.87984 26.44 7.25317C27.64 7.5865 28.4133 8.35984 28.7466 9.55984Z"
        fill="#FCFCFC"
      />
    </svg>
  ) : (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.66671 10.0002L10.1267 8.00016L6.66671 6.00016V10.0002ZM14.3734 4.78016C14.46 5.0935 14.52 5.5135 14.56 6.04683C14.6067 6.58016 14.6267 7.04016 14.6267 7.44016L14.6667 8.00016C14.6667 9.46016 14.56 10.5335 14.3734 11.2202C14.2067 11.8202 13.82 12.2068 13.22 12.3735C12.9067 12.4602 12.3334 12.5202 11.4534 12.5602C10.5867 12.6068 9.79337 12.6268 9.06004 12.6268L8.00004 12.6668C5.20671 12.6668 3.46671 12.5602 2.78004 12.3735C2.18004 12.2068 1.79337 11.8202 1.62671 11.2202C1.54004 10.9068 1.48004 10.4868 1.44004 9.9535C1.39337 9.42016 1.37337 8.96016 1.37337 8.56016L1.33337 8.00016C1.33337 6.54016 1.44004 5.46683 1.62671 4.78016C1.79337 4.18016 2.18004 3.7935 2.78004 3.62683C3.09337 3.54016 3.66671 3.48016 4.54671 3.44016C5.41337 3.3935 6.20671 3.3735 6.94004 3.3735L8.00004 3.3335C10.7934 3.3335 12.5334 3.44016 13.22 3.62683C13.82 3.7935 14.2067 4.18016 14.3734 4.78016Z"
        fill="#FCFCFC"
      />
    </svg>
  );
};
export default Youtube;
