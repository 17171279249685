import { Flex } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import MadjoIcon from "../../components/Icons/MadjoIcon.tsx";
import Typography from "../../components/typography/index.tsx";

const PublicOfferView = () => {
  return (
    <Flex
      vertical
      style={{ maxWidth: "800px", justifySelf: "center", marginBottom: "80px" }}
      justify="center"
      gap="24px"
    >
      <Flex vertical>
        <Flex justify="center" gap="12px" style={{ margin: "80px 0 40px" }}>
          {Array.from({ length: 3 }, (_, index) => (
            <MadjoIcon key={index} />
          ))}
        </Flex>
        <Flex vertical style={{ color: "#767D93", lineHeight: "22.4px" }}>
          <Typography variant="h1" style={{ padding: "8px 0" }}>Публічна оферта</Typography>

          <Typography style={{ lineHeight: "22.4px" }}>Дата набрання чинності: 20 червня 2024 року</Typography>

          <Typography style={{ lineHeight: "22.4px" }}>
            Ця публічна оферта (далі - "Оферта") є офіційною пропозицією ФОП
            ВЕРНІК МИХАЙЛО ОЛЕКСІЙОВИЧ (далі - "Компанія") на укладення договору
            про надання послуг через веб-сайт{" "}
            <a href="https://madjo.com.ua/" target="_blank">
              https://madjo.com.ua/
            </a>{" "}
            (далі - "Сайт") на умовах, викладених нижче.
          </Typography>

          <Typography variant="h2" style={{ padding: "8px 0" }}>1. Загальні положення</Typography>

          <Typography style={{ lineHeight: "22.4px" }}>
            1.1. Ця Оферта є публічною пропозицією Компанії укласти договір про
            надання послуг (далі - "Договір").
          </Typography>

          <Typography style={{ lineHeight: "22.4px" }}>
            1.2. Акцепт (прийняття) цієї Оферти користувачем (далі -
            "Користувач") є укладенням Договору на умовах, викладених у цій
            Оферті.
          </Typography>

          <Typography style={{ lineHeight: "22.4px" }}>
            1.3. Акцепт цієї Оферти здійснюється шляхом реєстрації на Сайті
            та/або використання Сайту для отримання послуг, що надаються
            Компанією.
          </Typography>

          <Typography variant="h2" style={{ padding: "8px 0" }}>2. Предмет договору</Typography>

          <Typography style={{ lineHeight: "22.4px" }}>
            2.1. Компанія зобов'язується надавати Користувачеві послуги через
            Сайт, а Користувач зобов'язується сплачувати за надані послуги
            відповідно до умов цієї Оферти.
          </Typography>

          <Typography style={{ lineHeight: "22.4px" }}>
            2.2. Перелік послуг, їх вартість та інші умови надання визначаються
            на відповідних сторінках Сайту.
          </Typography>

          <Typography style={{ lineHeight: "22.4px" }}>
            2.3. Компанія спеціалізується на виробництві та продажу власного
            одягу в Україні. Весь одяг, представлений на Сайті, виготовлений з
            високоякісних матеріалів і відповідає стандартам якості.
          </Typography>

          <Typography variant="h2" style={{ padding: "8px 0" }}>3. Права та обов'язки сторін</Typography>

          <Typography style={{ lineHeight: "22.4px" }}>3.1. Компанія зобов'язується:</Typography>

          <ul>
            <li>
              3.1.1. Надавати послуги Користувачеві відповідно до умов цієї
              Оферти та інформації, зазначеної на Сайті.
            </li>
            <li>
              3.1.2. Забезпечити конфіденційність персональних даних
              Користувача.
            </li>
            <li>
              3.1.3. Забезпечити високу якість виготовленого одягу та його
              відповідність опису на Сайті.
            </li>
          </ul>

          <Typography style={{ lineHeight: "22.4px" }}>3.2. Користувач зобов'язується:</Typography>

          <ul>
            <li>
              3.2.1. Надавати точну та актуальну інформацію, необхідну для
              надання послуг.
            </li>
            <li>
              3.2.2. Сплачувати за послуги в порядку та строки, встановлені цією
              Офертою та інформацією на Сайті.
            </li>
            <li>
              3.2.3. Дотримуватися умов цієї Оферти та інших правил, зазначених
              на Сайті.
            </li>
            <li>
              3.2.4. Використовувати наданий одяг відповідно до його призначення
              та дотримуватися правил догляду, зазначених на етикетці.
            </li>
          </ul>

          <Typography variant="h2" style={{ padding: "8px 0" }}>4. Вартість послуг та порядок розрахунків</Typography>

          <Typography style={{ lineHeight: "22.4px" }}>
            4.1. Вартість послуг визначається на відповідних сторінках Сайту та
            може змінюватися Компанією в односторонньому порядку.
          </Typography>

          <Typography style={{ lineHeight: "22.4px" }}>
            4.2. Оплата послуг здійснюється Користувачем за допомогою платіжної
            системи LiqPay, доступної на Сайті.
          </Typography>

          <Typography style={{ lineHeight: "22.4px" }}>
            4.3. Усі платежі здійснюються в національній валюті України -
            гривні.
          </Typography>

          <Typography style={{ lineHeight: "22.4px" }}>
            4.4. Всі фінансові транзакції здійснюються відповідно до правил і
            політик платіжної системи LiqPay.
          </Typography>

          <Typography variant="h2" style={{ padding: "8px 0" }}>5. Відповідальність сторін</Typography>

          <Typography style={{ lineHeight: "22.4px" }}>
            5.1. Компанія несе відповідальність за якість наданих послуг
            відповідно до умов цієї Оферти та чинного законодавства України.
          </Typography>

          <Typography style={{ lineHeight: "22.4px" }}>
            5.2. Користувач несе відповідальність за достовірність наданих даних
            та своєчасність оплати послуг.
          </Typography>

          <Typography style={{ lineHeight: "22.4px" }}>
            5.3. Компанія не несе відповідальності за недоліки одягу, що виникли
            внаслідок неправильного використання або догляду за ним
            Користувачем.
          </Typography>

          <Typography variant="h2" style={{ padding: "8px 0" }}>6. Вирішення спорів</Typography>

          <Typography style={{ lineHeight: "22.4px" }}>
            6.1. Всі спори та розбіжності, що можуть виникнути у зв'язку з
            виконанням цієї Оферти, вирішуються шляхом переговорів між
            сторонами.
          </Typography>

          <Typography style={{ lineHeight: "22.4px" }}>
            6.2. У разі неможливості досягнення згоди шляхом переговорів, спори
            вирішуються в судовому порядку відповідно до чинного законодавства
            України.
          </Typography>

          <Typography variant="h2" style={{ padding: "8px 0" }}>7. Інші умови</Typography>

          <Typography style={{ lineHeight: "22.4px" }}>
            7.1. Компанія має право вносити зміни до цієї Оферти в
            односторонньому порядку. Зміни набирають чинності з моменту їх
            публікації на Сайті.
          </Typography>

          <Typography style={{ lineHeight: "22.4px" }}>
            7.2. Користувач підтверджує, що ознайомився з умовами цієї Оферти та
            погоджується з ними, реєструючись на Сайті або використовуючи Сайт
            для отримання послуг.
          </Typography>

          <Typography style={{ lineHeight: "22.4px" }}>
            7.3. Ця Оферта є офіційним документом Компанії та публікується на
            Сайті.
          </Typography>

          <Typography variant="h2" style={{ padding: "8px 0" }}>8. Умови та правила оплати, доставки та повернення коштів</Typography>

          <Typography variant="h3" style={{ padding: "8px 0" }}>8.1. Оплата</Typography>

          <Typography style={{ lineHeight: "22.4px" }}>
            8.1.1. Оплата за замовлені товари здійснюється за допомогою
            платіжної системи LiqPay.
          </Typography>

          <Typography style={{ lineHeight: "22.4px" }}>
            8.1.2. Після успішного здійснення оплати Користувач отримує
            електронне підтвердження оплати на вказану при реєстрації електронну
            адресу.
          </Typography>

          <Typography variant="h3" style={{ padding: "8px 0" }}>8.2. Доставка</Typography>

          <Typography style={{ lineHeight: "22.4px" }}>
            8.2.1. Доставка товарів здійснюється по всій території України
            кур'єрськими службами або поштовими операторами, зазначеними на
            Сайті.
          </Typography>

          <Typography style={{ lineHeight: "22.4px" }}>
            8.2.2. Термін доставки залежить від обраного способу доставки та
            регіону призначення, та зазвичай становить від 3 до 7 робочих днів.
          </Typography>

          <Typography style={{ lineHeight: "22.4px" }}>
            8.2.3. Вартість доставки розраховується окремо і вказується при
            оформленні замовлення.
          </Typography>

          <Typography variant="h3" style={{ padding: "8px 0" }}>8.3. Повернення коштів</Typography>

          <Typography style={{ lineHeight: "22.4px" }}>
            8.3.1. Користувач має право повернути товар протягом 14 днів з
            моменту отримання, якщо товар не був у використанні, збережено його
            товарний вигляд, споживчі властивості, фабричні ярлики, а також
            касовий або товарний чек, видані Користувачу разом з товаром.
          </Typography>

          <Typography style={{ lineHeight: "22.4px" }}>
            8.3.2. Для повернення товару Користувач повинен зв'язатися з
            Компанією через контактні дані, зазначені на Сайті, та заповнити
            форму на повернення товару.
          </Typography>

          <Typography style={{ lineHeight: "22.4px" }}>
            8.3.3. Повернення коштів здійснюється на банківську карту
            Користувача протягом 7 робочих днів з моменту отримання Компанією
            повернутого товару та підтвердження його відповідності умовам
            повернення.
          </Typography>

          <Typography variant="h2" style={{ padding: "8px 0" }}>Контактна інформація</Typography>
          <Typography style={{ lineHeight: "22.4px" }}>
            Юридична адреса: 02222, Україна, місто Київ, проспект Червоної Калини, будинок, 49, квартира, 48
          </Typography>
          <Typography style={{ lineHeight: "22.4px" }}>
            Якщо у Вас є будь-які запитання щодо цієї Оферти, Ви можете
            зв'язатися з нами:
          </Typography>

          <ul>
            <li>Електронною поштою: info@madjo.com.ua</li>
            <li>За номером телефону: +380959258732</li>
          </ul>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default PublicOfferView;
