import { Col, Row } from "antd";
import React from "react";
import { useLoaderData } from "react-router";
import { Link } from "react-router-dom";
import { Product, shopService } from "../../services/shopService.ts";
import ProductPreview from "../products/ProductPreview.tsx";
import { ProductSearch } from "../../App.tsx";
import useBreakpoints from "../../hooks/useBreakpoints.ts";

export const collectionLoader = async ({
  params,
}): Promise<Product[] | undefined> => {
  const product = (await shopService.getCollection(
    params.collectionId
  )) as Product[];
  return product;
};

const CollectionView = () => {
  const breakpoint = useBreakpoints();
  const isMobile = ["xs"].includes(breakpoint);
  const collectionProducts = useLoaderData() as Product[];
  const [products, setProducts] = React.useState<Product[] | undefined>(collectionProducts);
  const { search } = React.useContext(ProductSearch);
  
  React.useEffect(() => {
    shopService.getSexBasedProducts(undefined, search).then(setProducts);
  }, [search]);

  return (
    <Row>
      {products &&
        products.map(
          (p) =>
            p.productImages &&
            p.productImages.length > 0 && (
              <Col span={isMobile ? 12 : 6}>
                <Link to={`/products/${p.productId}`}>
                  <ProductPreview key={p.productId} product={p} />
                </Link>
              </Col>
            )
        )}
    </Row>
  );
};

export default CollectionView;
