import { Flex } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import MadjoIcon from "../../components/Icons/MadjoIcon.tsx";
import Typography from "../../components/typography/index.tsx";

const PrivacyPolicyView = () => {
  return (
    <Flex
      vertical
      style={{ maxWidth: "800px", justifySelf: "center", margin: "0 16px 80px 16px" }}
      justify="center"
      gap="24px"
    >
      <Flex vertical>
        <Flex justify="center" gap="12px" style={{ margin: "80px 0 40px" }}>
          {Array.from({ length: 3 }, (_, index) => (
            <MadjoIcon key={index} />
          ))}
        </Flex>
        <Flex vertical style={{ color: "#767D93", lineHeight: "22.4px" }}>
          <Typography variant="h1" style={{ padding: "8px 0" }}>
            Політика конфіденційності
          </Typography>

          <Typography style={{ lineHeight: "22.4px" }}>Останнє оновлення: 20 червня 2024 року</Typography>
          <Typography style={{ lineHeight: "22.4px" }}>
            Ця Політика конфіденційності описує наші політики та процедури щодо
            збору, використання та розкриття Вашої інформації, коли Ви
            використовуєте Сервіс, і повідомляє Вам про Ваші права на
            конфіденційність та про те, як закон захищає Вас.
          </Typography>
          <Typography style={{ lineHeight: "22.4px" }}>
            Ми використовуємо Ваші персональні дані для надання та покращення
            Сервісу. Використовуючи Сервіс, Ви погоджуєтесь на збір та
            використання інформації відповідно до цієї Політики
            конфіденційності.
          </Typography>
          <Typography variant="h2" style={{ padding: "8px 0" }}>Тлумачення та визначення</Typography>
          <Typography variant="h3" style={{ padding: "8px 0" }}>Тлумачення</Typography>
          <Typography style={{ lineHeight: "22.4px" }}>
            Слова, початкова літера яких написана з великої літери, мають
            значення, визначені в наступних умовах. Наступні визначення мають те
            саме значення незалежно від того, чи з'являються вони в однині чи
            множині.
          </Typography>
          <Typography variant="h3" style={{ padding: "8px 0" }}>Визначення</Typography>
          <Typography style={{ lineHeight: "22.4px" }}>Для цілей цієї Політики конфіденційності:</Typography>
          <ul>
            <li>
              <Typography style={{ lineHeight: "22.4px" }}>
                <Typography variant="bold">Обліковий запис</Typography> означає
                унікальний обліковий запис, створений для Вас для доступу до
                нашого Сервісу або його частин.
              </Typography>
            </li>
            <li>
              <Typography style={{ lineHeight: "22.4px" }}>
                <Typography variant="bold">Афілійована особа</Typography>{" "}
                означає суб'єкт, який контролює, контролюється або знаходиться
                під спільним контролем з стороною, де «контроль» означає
                володіння 50% або більше акцій, пайової участі або інших цінних
                паперів, що мають право голосу при обранні директорів або іншого
                керуючого органу.
              </Typography>
            </li>
            <li>
              <Typography style={{ lineHeight: "22.4px" }}>
                <Typography variant="bold">Компанія</Typography> (зазначається
                як "Компанія", "Ми", "Нас" або "Наш" у цій Угоді) стосується ТОВ
                «МАДЖО», м. Київ, вул. Вишняківська 13Б.
              </Typography>
            </li>
            <li>
              <Typography style={{ lineHeight: "22.4px" }}>
                <Typography variant="bold">Файли cookie</Typography> – це
                невеликі файли, які розміщуються на Вашому комп'ютері,
                мобільному пристрої або будь-якому іншому пристрої веб-сайтом,
                що містять деталі Вашої історії перегляду на цьому веб-сайті
                серед багатьох його використань.
              </Typography>
            </li>
            <li>
              <Typography style={{ lineHeight: "22.4px" }}>
                <Typography variant="bold">Країна</Typography> стосується:
                Україна
              </Typography>
            </li>
            <li>
              <Typography style={{ lineHeight: "22.4px" }}>
                <Typography variant="bold">Пристрій</Typography> означає
                будь-який пристрій, який може отримати доступ до Сервісу,
                наприклад комп'ютер, мобільний телефон або цифровий планшет.
              </Typography>
            </li>
            <li>
              <Typography style={{ lineHeight: "22.4px" }}>
                <Typography variant="bold">Персональні дані</Typography> - це
                будь-яка інформація, яка стосується ідентифікованої або
                ідентифікованої особи.
              </Typography>
            </li>
            <li>
              <Typography style={{ lineHeight: "22.4px" }}>
                <Typography variant="bold">Сервіс</Typography> відноситься до
                Веб-сайту.
              </Typography>
            </li>
            <li>
              <Typography style={{ lineHeight: "22.4px" }}>
                <Typography variant="bold">Постачальник послуг</Typography>{" "}
                означає будь-яку фізичну або юридичну особу, яка обробляє дані
                від імені Компанії. Це стосується сторонніх компаній або осіб,
                найнятих Компанією для полегшення надання Сервісу, для надання
                Сервісу від імені Компанії, для виконання послуг, пов'язаних із
                Сервісом, або для надання допомоги Компанії в аналізі того, як
                використовується Сервіс.
              </Typography>
            </li>
            <li>
              <Typography style={{ lineHeight: "22.4px" }}>
                <Typography variant="bold">Дані про використання</Typography>{" "}
                відносяться до даних, які збираються автоматично, або
                згенерованих у процесі використання Сервісу, або з
                інфраструктури Сервісу (наприклад, тривалість відвідування
                сторінки).
              </Typography>
            </li>
            <li>
              <Typography style={{ lineHeight: "22.4px" }}>
                <Typography variant="bold">Веб-сайт</Typography> відноситься до
                MADJO, доступного з{" "}
                <Link
                  to={"/privacy-policy"}
                  style={{ textDecorationLine: "underline", color: "#9FB3D6" }}
                >
                  https://madjo.com.ua/
                </Link>
              </Typography>
            </li>
            <li>
              <Typography style={{ lineHeight: "22.4px" }}>
                <Typography variant="bold">Ви</Typography> означає фізичну
                особу, яка отримує доступ або використовує Сервіс, або компанію
                чи іншу юридичну особу, від імені якої така фізична особа
                отримує доступ або використовує Сервіс, залежно від обставин.
              </Typography>
            </li>
          </ul>
          <Typography variant="h2" style={{ padding: "8px 0" }}>
            Збір та використання Ваших персональних даних
          </Typography>
          <Typography variant="h3" style={{ padding: "8px 0" }}>Типи зібраних даних</Typography>
          <Typography variant="h4" style={{ padding: "8px 0" }}>Персональні дані</Typography>
          <Typography style={{ lineHeight: "22.4px" }}>
            Під час використання нашого Сервісу ми можемо попросити Вас надати
            нам певну особисту інформацію, яка може бути використана для зв'язку
            або ідентифікації Вас. Особисто ідентифікуюча інформація може
            включати, але не обмежується:
          </Typography>
          <ul>
            <li>Дані про використання</li>
          </ul>
          <Typography variant="h4" style={{ padding: "8px 0" }}>Дані про використання</Typography>
          <Typography style={{ lineHeight: "22.4px" }}>
            Дані про використання збираються автоматично під час використання
            Сервісу.
          </Typography>
          <Typography style={{ lineHeight: "22.4px" }}>
            Дані про використання можуть включати таку інформацію, як
            Інтернет-протокол (наприклад, IP-адреса) Вашого пристрою, тип
            браузера, версія браузера, сторінки нашого Сервісу, які Ви
            відвідуєте, час та дата Вашого відвідування, час, проведений на цих
            сторінках, унікальні ідентифікатори пристрою та інші діагностичні
            дані.
          </Typography>
          <Typography style={{ lineHeight: "22.4px" }}>
            Коли Ви отримуєте доступ до Сервісу за допомогою мобільного пристрою
            або через нього, ми можемо автоматично збирати певну інформацію,
            включаючи, але не обмежуючись, тип мобільного пристрою, який Ви
            використовуєте, унікальний ідентифікатор Вашого мобільного пристрою,
            IP-адресу Вашого мобільного пристрою, операційну систему Вашого
            мобільного пристрою, тип мобільного Інтернет-браузера, який Ви
            використовуєте, унікальні ідентифікатори пристрою та інші
            діагностичні дані.
          </Typography>
          <Typography style={{ lineHeight: "22.4px" }}>
            Ми також можемо збирати інформацію, яку Ваш браузер надсилає щоразу,
            коли Ви відвідуєте наш Сервіс або коли Ви отримуєте доступ до
            Сервісу за допомогою мобільного пристрою або через нього.
          </Typography>
          <Typography variant="h4" style={{ padding: "8px 0" }}>
            Технології відстеження та файли cookie
          </Typography>
          <Typography style={{ lineHeight: "22.4px" }}>
            Ми використовуємо файли cookie та подібні технології відстеження,
            щоб відстежувати активність на нашому Сервісі та зберігати певну
            інформацію. Використовувані технології відстеження включають маяки,
            теги та скрипти для збору та відстеження інформації та для
            покращення та аналізу нашого Сервісу. Використовувані нами
            технології можуть включати:
          </Typography>
          <ul>
            <li>
              <Typography variant="bold">
                Файли cookie або браузерні файли cookie.
              </Typography>{" "}
              Файл cookie – це невеликий файл, що розміщується на Вашому
              Пристрої. Ви можете налаштувати свій браузер на відмову від усіх
              файлів cookie або на індикацію, коли файл cookie надсилається.
              Однак, якщо Ви не приймаєте файли cookie, Ви не зможете
              використовувати деякі частини нашого Сервісу. Якщо Ви не
              налаштували свій браузер таким чином, щоб він відмовлявся від
              файлів cookie, наш Сервіс може використовувати файли cookie.
            </li>
            <li>
              <Typography variant="bold">Веб-маяки.</Typography> Деякі розділи
              нашого Сервісу та наші електронні листи можуть містити невеликі
              електронні файли, відомі як веб-маяки (також звані прозорими
              gif-файлами, піксельними тегами та однопіксельними gif-файлами),
              які дозволяють Компанії, наприклад , підраховувати користувачів,
              які відвідали ці сторінки або відкрили електронний лист та для
              інших пов'язаних з веб-сайтом статистичних даних (наприклад, запис
              популярності певного розділу та перевірки цілісності системи та
              сервера).
            </li>
          </ul>
          <Typography style={{ lineHeight: "22.4px" }}>
            Файли cookie можуть бути "постійними" або "сесійними" файлами
            cookie. Постійні файли cookie залишаються на Вашому персональному
            комп'ютері або мобільному пристрої, коли Ви переходите в
            офлайн-режим, тоді як сесійні файли cookie видаляються, як тільки Ви
            закриваєте свій веб-браузер.
          </Typography>
          <Typography style={{ lineHeight: "22.4px" }}>
            Ми використовуємо як сесійні, так і постійні файли cookie для цілей,
            викладених нижче:
          </Typography>
          <ul>
            <li>
              <Typography style={{ lineHeight: "22.4px" }}>
                <Typography variant="bold">
                  Необхідні / Основні файли cookie
                </Typography>
              </Typography>
              <Typography style={{ lineHeight: "22.4px" }}>Тип: Сесійні файли cookie</Typography>
              <Typography style={{ lineHeight: "22.4px" }}>Адмініструється нами</Typography>
              <Typography style={{ lineHeight: "22.4px" }}>
                Призначення: Ці файли cookie є важливими для надання Вам послуг,
                доступних через веб-сайт, і для забезпечення можливості
                використання деяких його функцій. Вони допомагають
                автентифікувати користувачів та запобігати шахрайському
                використанню облікових записів користувачів. Без цих файлів
                cookie послуги, які Ви запитували, не можуть бути надані, і ми
                використовуємо ці файли cookie лише для надання Вам цих послуг.
              </Typography>
            </li>
            <li>
              <Typography style={{ lineHeight: "22.4px" }}>
                <Typography variant="bold">
                  Політика щодо файлів cookie / Повідомлення про прийняття
                  файлів cookie
                </Typography>
              </Typography>
              <Typography style={{ lineHeight: "22.4px" }}>Тип: Постійні файли cookie</Typography>
              <Typography style={{ lineHeight: "22.4px" }}>Адмініструється нами</Typography>
              <Typography style={{ lineHeight: "22.4px" }}>
                Призначення: Ці файли cookie визначають, чи прийняли користувачі
                використання файлів cookie на веб-сайті.
              </Typography>
            </li>
            <li>
              <Typography style={{ lineHeight: "22.4px" }}>
                <Typography variant="bold">
                  Файли cookie функціональності
                </Typography>
              </Typography>
              <Typography style={{ lineHeight: "22.4px" }}>Тип: Постійні файли cookie</Typography>
              <Typography style={{ lineHeight: "22.4px" }}>Адмініструється нами</Typography>
              <Typography style={{ lineHeight: "22.4px" }}>
                Призначення: Ці файли cookie дозволяють нам запам'ятовувати
                вибір, який Ви робите під час використання веб-сайту, наприклад,
                запам'ятовування Ваших даних для входу або мовних налаштувань.
                Метою цих файлів cookie є надання Вам більш особистого досвіду
                та уникнення необхідності повторно вводити свої уподобання
                щоразу, коли Ви використовуєте веб-сайт.
              </Typography>
            </li>
          </ul>
          <Typography style={{ lineHeight: "22.4px" }}>
            Для отримання додаткової інформації про файли cookie, які ми
            використовуємо, і Ваш вибір щодо файлів cookie, будь ласка,
            відвідайте нашу Політику щодо файлів cookie або розділ файлів cookie
            нашої Політики конфіденційності.
          </Typography>
          <Typography variant="h3" style={{ padding: "8px 0" }}>
            Використання Ваших персональних даних
          </Typography>
          <Typography style={{ lineHeight: "22.4px" }}>
            Компанія може використовувати Персональні дані для таких цілей:
          </Typography>
          <ul>
            <li>
              <Typography style={{ lineHeight: "22.4px" }}>
                <Typography variant="bold">
                  Для надання та підтримки нашого Сервісу
                </Typography>
                , включаючи моніторинг використання нашого Сервісу.
              </Typography>
            </li>
            <li>
              <Typography style={{ lineHeight: "22.4px" }}>
                <Typography variant="bold">
                  Для управління Вашим Обліковим записом:
                </Typography>{" "}
                для управління Вашою реєстрацією як користувача Сервісу.
                Персональні дані, які Ви надаєте, можуть надати Вам доступ до
                різних функцій Сервісу, які доступні Вам як зареєстрованому
                користувачу.
              </Typography>
            </li>
            <li>
              <Typography style={{ lineHeight: "22.4px" }}>
                <Typography variant="bold">Для виконання контракту:</Typography>{" "}
                розробка, виконання та дотримання договору купівлі-продажу
                продуктів, предметів або послуг, які Ви придбали, або будь-якого
                іншого договору з нами через Сервіс.
              </Typography>
            </li>
            <li>
              <Typography style={{ lineHeight: "22.4px" }}>
                <Typography variant="bold">Для зв'язку з Вами:</Typography> Для
                зв'язку з Вами електронною поштою, телефонними дзвінками, SMS
                або іншими еквівалентними формами електронного зв'язку, такими
                як push-повідомлення мобільного додатку, щодо оновлень або
                інформаційних повідомлень, пов'язаних з функціями, продуктами
                або контрактованими послугами, включаючи оновлення безпеки, коли
                це необхідно або розумно для їх реалізації.
              </Typography>
            </li>
            <li>
              <Typography style={{ lineHeight: "22.4px" }}>
                <Typography variant="bold">Для надання Вам</Typography> новин,
                спеціальних пропозицій та загальної інформації про інші товари,
                послуги та події, які ми пропонуємо, що схожі на ті, які Ви вже
                придбали або про які запитували, якщо Ви не вирішили не
                отримувати таку інформацію.
              </Typography>
            </li>
            <li>
              <Typography style={{ lineHeight: "22.4px" }}>
                <Typography variant="bold">
                  Для управління Вашими запитами:
                </Typography>{" "}
                Для участі та управління Вашими запитами до нас.
              </Typography>
            </li>
            <li>
              <Typography style={{ lineHeight: "22.4px" }}>
                <Typography variant="bold">Для передачі бізнесу:</Typography> Ми
                можемо використовувати Вашу інформацію для оцінки або проведення
                злиття, відчуження, реорганізації, реструктуризації, розпуску
                або іншого продажу або передачі деяких або всіх наших активів,
                незалежно від того, чи є це як частина діяльності, чи як частина
                банкрутства, ліквідації або подібного процесу, в якому
                Персональні дані, що зберігаються у нас про користувачів
                Сервісу, є серед переданих активів.
              </Typography>
            </li>
            <li>
              <Typography style={{ lineHeight: "22.4px" }}>
                <Typography variant="bold">Для інших цілей</Typography>: Ми
                можемо використовувати Вашу інформацію для інших цілей, таких як
                аналіз даних, виявлення тенденцій використання, визначення
                ефективності наших рекламних кампаній та оцінка та покращення
                нашого Сервісу, продуктів, послуг, маркетингу та Вашого досвіду.
              </Typography>
            </li>
          </ul>
          <Typography style={{ lineHeight: "22.4px" }}>
            Ми можемо передавати Вашу особисту інформацію в наступних ситуаціях:
          </Typography>
          <ul>
            <li>
              <Typography variant="bold">З Постачальниками послуг:</Typography>{" "}
              Ми можемо передавати Вашу особисту інформацію Постачальникам
              послуг для моніторингу та аналізу використання нашого Сервісу, для
              зв'язку з Вами.
            </li>
            <li>
              <Typography variant="bold">Для передачі бізнесу:</Typography> Ми
              можемо передавати або передавати Вашу особисту інформацію у
              зв'язку з, або під час переговорів про будь-яке злиття, продаж
              активів Компанії, фінансування або придбання всієї або частини
              нашого бізнесу іншою компанією.
            </li>
            <li>
              <Typography variant="bold">З афілійованими особами:</Typography>{" "}
              Ми можемо передавати Вашу інформацію нашим афілійованим особам, у
              такому випадку ми вимагатимемо від цих афілійованих осіб
              дотримуватися цієї Політики конфіденційності. Афілійовані особи
              включають нашу материнську компанію та будь-які інші дочірні
              компанії, партнерів по спільному підприємству або інші компанії,
              які ми контролюємо або які знаходяться під спільним контролем з
              нами.
            </li>
            <li>
              <Typography variant="bold">З бізнес-партнерами:</Typography> Ми
              можемо передавати Вашу інформацію нашим бізнес-партнерам для
              пропозиції Вам певних продуктів, послуг або акцій.
            </li>
            <li>
              <Typography variant="bold">З іншими користувачами:</Typography>{" "}
              коли Ви передаєте особисту інформацію або іншим чином взаємодієте
              в публічних зонах з іншими користувачами, така інформація може
              бути переглянута всіма користувачами і може бути публічно
              розповсюджена назовні.
            </li>
            <li>
              <Typography variant="bold">З Вашою згодою</Typography>: Ми можемо
              розкривати Вашу особисту інформацію для будь-якої іншої мети з
              Вашою згодою.
            </li>
          </ul>
          <Typography variant="h3" style={{ padding: "8px 0" }}>
            Зберігання Ваших персональних даних
          </Typography>
          <Typography style={{ lineHeight: "22.4px" }}>
            Компанія зберігатиме Ваші Персональні дані лише протягом необхідного
            часу для досягнення цілей, викладених у цій Політиці
            конфіденційності. Ми зберігатимемо та використовуватимемо Ваші
            Персональні дані в міру необхідності для викон ання наших юридичних
            зобов'язань (наприклад, якщо ми зобов'язані зберігати Ваші дані для
            дотримання чинних законів), вирішення суперечок та виконання наших
            юридичних угод та політик.
          </Typography>
          <Typography style={{ lineHeight: "22.4px" }}>
            Компанія також зберігатиме Дані про використання для внутрішніх
            аналітичних цілей. Дані про використання зазвичай зберігаються
            протягом коротшого періоду часу, за винятком випадків, коли ці дані
            використовуються для зміцнення безпеки або для покращення
            функціональності нашого Сервісу, або ми юридично зобов'язані
            зберігати ці дані протягом довших періодів часу.
          </Typography>
          <Typography variant="h3" style={{ padding: "8px 0" }}>
            Передача Ваших персональних даних
          </Typography>
          <Typography style={{ lineHeight: "22.4px" }}>
            Ваша інформація, включаючи Персональні дані, обробляється в
            операційних офісах Компанії та в будь-яких інших місцях, де
            знаходяться сторони, залучені до обробки. Це означає, що ця
            інформація може бути передана — і зберігатися — на комп'ютерах,
            розташованих за межами Вашої держави, провінції, країни або іншої
            державної юрисдикції, де закони про захист даних можуть відрізнятися
            від законів Вашої юрисдикції.
          </Typography>
          <Typography style={{ lineHeight: "22.4px" }}>
            Ваша згода з цією Політикою конфіденційності, супроводжувана
            наданням такої інформації, означає Вашу згоду на таку передачу.
          </Typography>
          <Typography style={{ lineHeight: "22.4px" }}>
            Компанія вживатиме всіх необхідних заходів, щоб забезпечити безпечне
            поводження з Вашими даними відповідно до цієї Політики
            конфіденційності, і жодна передача Ваших Персональних даних не
            відбуватиметься в організацію або країну, якщо не будуть впроваджені
            належні засоби контролю, включаючи безпеку Ваших даних та іншої
            особистої інформації.
          </Typography>
          <Typography variant="h3" style={{ padding: "8px 0" }}>
            Видалення Ваших персональних даних
          </Typography>
          <Typography style={{ lineHeight: "22.4px" }}>
            Ви маєте право видалити або запросити, щоб ми допомогли у видаленні
            Персональних даних, які ми зібрали про Вас.
          </Typography>
          <Typography style={{ lineHeight: "22.4px" }}>
            Наш Сервіс може надати Вам можливість видалити певну інформацію про
            Вас із Сервісу.
          </Typography>
          <Typography style={{ lineHeight: "22.4px" }}>
            Ви можете оновлювати, змінювати або видаляти свою інформацію в
            будь-який час, увійшовши до свого Облікового запису, якщо у Вас є
            обліковий запис, і відвідавши розділ налаштувань облікового запису,
            який дозволяє Вам керувати Вашою особистою інформацією. Ви також
            можете зв'язатися з нами, щоб запросити доступ до, виправити або
            видалити будь-яку особисту інформацію, яку Ви надали нам.
          </Typography>
          <Typography style={{ lineHeight: "22.4px" }}>
            Зверніть увагу, що ми можемо зберігати певну інформацію, коли ми
            маємо юридичне зобов'язання або законну підставу для цього.
          </Typography>
          <Typography variant="h3" style={{ padding: "8px 0" }}>
            Розкриття Ваших персональних даних
          </Typography>
          <Typography variant="h4" style={{ padding: "8px 0" }}>Ділові операції</Typography>
          <Typography style={{ lineHeight: "22.4px" }}>
            Якщо Компанія бере участь у злитті, придбанні або продажу активів,
            Ваші Персональні дані можуть бути передані. Ми надамо повідомлення
            перед тим, як Ваші Персональні дані будуть передані та стануть
            предметом іншої Політики конфіденційності.
          </Typography>
          <Typography variant="h4" style={{ padding: "8px 0" }}>Правоохоронні органи</Typography>
          <Typography style={{ lineHeight: "22.4px" }}>
            За певних обставин Компанія може бути зобов'язана розкрити Ваші
            Персональні дані, якщо це вимагається законом або у відповідь на
            дійсні запити державних органів (наприклад, суду або урядової
            агенції).
          </Typography>
          <Typography variant="h4" style={{ padding: "8px 0" }}>Інші юридичні вимоги</Typography>
          <Typography style={{ lineHeight: "22.4px" }}>
            Компанія може розкрити Ваші Персональні дані в добросовісному
            переконанні, що такі дії необхідні для:
          </Typography>
          <ul>
            <li>Виконання юридичного зобов'язання</li>
            <li>Захисту та оборони прав або власності Компанії</li>
            <li>
              Запобігання або розслідування можливих неправомірних дій у зв'язку
              з Сервісом
            </li>
            <li>
              Захисту особистої безпеки користувачів Сервісу або громадськості
            </li>
            <li>Захисту від юридичної відповідальності</li>
          </ul>
          <Typography variant="h3" style={{ padding: "8px 0" }}>Безпека Ваших персональних даних</Typography>
          <Typography style={{ lineHeight: "22.4px" }}>
            Безпека Ваших Персональних даних важлива для нас, але пам'ятайте, що
            жоден метод передачі через Інтернет або метод електронного
            зберігання не є 100% безпечним. Хоча ми прагнемо використовувати
            комерційно прийнятні засоби для захисту Ваших Персональних даних, ми
            не можемо гарантувати їх абсолютну безпеку.
          </Typography>
          <Typography variant="h2" style={{ padding: "8px 0" }}>Конфіденційність дітей</Typography>
          <Typography style={{ lineHeight: "22.4px" }}>
            Наш Сервіс не адресований особам, які не досягли 13 років. Ми не
            збираємо особисту інформацію від осіб, які не досягли 13 років. Якщо
            Ви є батьком або опікуном і знаєте, що Ваша дитина надала нам
            Персональні дані, будь ласка, зв'яжіться з нами. Якщо ми дізнаємося,
            що ми зібрали Персональні дані від особи, яка не досягла 13 років
            без перевірки батьківської згоди, ми вживаємо заходів для видалення
            цієї інформації з наших серверів.
          </Typography>
          <Typography style={{ lineHeight: "22.4px" }}>
            Якщо нам потрібно покладатися на згоду як на юридичну підставу для
            обробки Вашої інформації та Ваша країна вимагає згоди батьків, ми
            можемо вимагати згоди Ваших батьків перед тим, як збирати та
            використовувати цю інформацію.
          </Typography>
          <Typography variant="h2" style={{ padding: "8px 0" }}>Посилання на інші веб-сайти</Typography>
          <Typography style={{ lineHeight: "22.4px" }}>
            Наш Сервіс може містити посилання на інші веб-сайти, які не
            керуються нами. Якщо Ви натиснете на стороннє посилання, Ви
            перейдете на сайт цієї сторони. Ми настійно рекомендуємо Вам
            переглянути Політику конфіденційності кожного сайту, який Ви
            відвідуєте.
          </Typography>
          <Typography style={{ lineHeight: "22.4px" }}>
            Ми не контролюємо і не несемо відповідальності за вміст, політики
            конфіденційності або практики будь-яких сторонніх сайтів або послуг.
          </Typography>
          <Typography variant="h2" style={{ padding: "8px 0" }}>
            Зміни до цієї Політики конфіденційності
          </Typography>
          <Typography style={{ lineHeight: "22.4px" }}>
            Ми можемо періодично оновлювати нашу Політику конфіденційності. Ми
            повідомимо Вас про будь-які зміни, розмістивши нову Політику
            конфіденційності на цій сторінці.
          </Typography>
          <Typography style={{ lineHeight: "22.4px" }}>
            Ми повідомимо Вас електронною поштою та/або помітним повідомленням
            на нашому Сервісі, до того, як зміна набере чинності, та оновимо
            дату "Останнє оновлення" у верхній частині цієї Політики
            конфіденційності.
          </Typography>
          <Typography style={{ lineHeight: "22.4px" }}>
            Вам рекомендується періодично переглядати цю Політику
            конфіденційності на предмет будь-яких змін. Зміни до цієї Політики
            конфіденційності набувають чинності з моменту їх публікації на цій
            сторінці.
          </Typography>
          <Typography variant="h2" style={{ padding: "8px 0" }}>Зв'яжіться з нами</Typography>
          <Typography style={{ lineHeight: "22.4px" }}>
            Якщо у Вас є будь-які запитання щодо цієї Політики конфіденційності,
            Ви можете зв'язатися з нами:
          </Typography>
          <ul>
            <li>
              <Typography style={{ lineHeight: "22.4px" }}>Електронною поштою: info@madjo.com.ua</Typography>
            </li>
            <li>
              <Typography style={{ lineHeight: "22.4px" }}>За номером телефону: +380507133136</Typography>
            </li>
          </ul>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default PrivacyPolicyView;
