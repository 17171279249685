import { Carousel, Col, Flex, Image, Row } from "antd";
import * as React from "react";
import { Link } from "react-router-dom";
import Loading from "../../components/loader/Loading.tsx";
import Typography from "../../components/typography/index.tsx";
import ProductPreview from "../products/ProductPreview.tsx";
import { Collection, Product } from "../../services/shopService.ts";
import styled from "styled-components";
import { CarouselWrapper } from "./CarouselWrapper.ts";

interface MainMobileInterface {
  collections: Collection[];
}

const MainMobile = ({ collections }: MainMobileInterface) => {
  return (
    <Flex justify="center">
      {collections ? (
        collections.map(
          (c) =>
            c.products != undefined &&
            c.products.length > 0 && (
              <Flex
                key={c.collectionId}
                style={{ margin: "16px" }}
                vertical
                gap="16px"
              >
                <Flex vertical style={{ alignSelf: "center" }} gap="16px">
                  <Image
                    src={c.imageLink}
                    preview={false}
                    style={{
                      width: "328px",
                      height: "280px",
                      objectPosition: "right",
                      objectFit: "cover",
                    }}
                  />
                  <Typography variant="h3">{c.name.toUpperCase()}</Typography>
                  <Link to={`collections/${c.collectionId}`}>
                    <Typography
                      variant="uppercase"
                      style={{
                        paddingBottom: "8px",
                        borderBottom: "1px solid #9FB3D6",
                      }}
                    >
                      Переглянути
                    </Typography>
                  </Link>
                </Flex>
                {/* <Row> */}
                <CarouselWrapper
                  style={{ width: "328px", marginBottom: "16px" }}
                  slidesPerRow={2}
                >
                  {c.products.map(
                    (p) =>
                      p.productImages &&
                      p.productImages.length > 0 && (
                        <Link to={`products/${p.productId}`}>
                          <ProductPreview key={p.productId} product={p} />
                        </Link>
                      )
                  )}
                </CarouselWrapper>
                {/* </Row> */}
              </Flex>
            )
        )
      ) : (
        <Loading size={"126px"} />
      )}
    </Flex>
  );
};

export default MainMobile;
